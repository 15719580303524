.thin() {
  font-weight: 100;
}

.light() {
  font-weight: 300;
}

// 常规体
.regular() {
  font-weight: 400;
}

// 中黑体
.medium() {
  font-weight: 500;
}

// 中粗体
.semibold() {
  font-weight: 700;
}

.heavy() {
  font-weight: 800;
}