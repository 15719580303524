@import "../../style/mixins/font-weight.less";
@import "../../style/themes/default.less";
@import "../../style/mixins/utils.less";

.container {
  padding: 20px;
  box-sizing: border-box;
  width: @help-width;
  .text {
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 22px;
    .regular();
    color: #484848;
  }
  .link {
    .help-link();
  }
}