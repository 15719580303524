@import '../../style/mixins/font-weight.less';

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30px;
  &:hover {
    .menuInner {
      color: #fff;
      .name {
        .semibold();
      }
    }
  }
  .menuInner {
    text-align: center;
    line-height: 0;
    &:focus {
      outline: none;
    }
    .icon {
      width: 26px;
      height: 26px;
    }
    .name {
      font-size: 14px;
      line-height: 20px;
      margin: 2px 0 0;
      user-select: none;
    }
  }
}

:global {
  .ant-menu-submenu-arrow {
    display: none;
  }
  .ant-menu {
    border: none;
    .ant-menu-item {
      font-size: 14px;
      .regular();
      color: #fff;
      box-sizing: border-box;
      padding: 3px 0 3px 10px;
      margin: 7px 0!important;
      &:hover {
        .semibold();
      }
    }
  }
  .ant-menu-submenu-title {
    padding: 0 !important;
    margin: 0!important;
    background-color: transparent;
    &:focus {
      outline: none;
    }
  }
  .ant-menu-submenu {
    color: rgba(255, 255, 255, 0.8);
    .regular();
    &:focus {
      outline: none;
    }
    &.ant-menu-submenu-selected {
      color: #fff;
      .semibold();
    }
  }
  .ant-menu-sub {
    min-width: 100px!important;
  }
}