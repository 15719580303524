@import "../../style/themes/default.less";

.container {
  width: @help-width;
  .iconWrap {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    .icon {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
}