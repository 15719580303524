@import '../../style/themes/default.less';
@import '../../style/mixins/font-weight.less';

.helpHead {
  width: 132px;
  height: @layout-header-height;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all @help-transition-duration;
  border-left: 1px solid @border-color;
  &.expand {
    width: @help-width;
    .helpHeadIcon {
      transform: scale(1, 1);
    }
  }
  .helpHeadTitle {
    line-height: 20px;
    font-size: 14px;
    color: #484848;
    .semibold();
  }
  .helpHeadIcon {
    transition: all @help-transition-duration;
    width: 16px;
    height: 16px;
    color: #DFDFDF;
    cursor: pointer;
    transform: scale(-1, 1);
  }
}

.tip {
  font-size: 12px;
  color: #438FE7;
  .regular();
}