@import "../themes/default.less";
@import "./font-weight.less";

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.default-page-container {
  background-color: @bg-color;
  height: 100%;
  .hide-scrollbar();
  overflow: auto;
}

.help-link {
  font-size: 14px;
  line-height: 22px;
  .regular();
  color: #52C41A!important;
  word-break: break-all;
}

.btn-primary {
  background-color: #1890ff!important;
  color: #fff!important;
  border: none!important;
}

.table {
  :global {
    .ant-table-pagination {
      margin: 30px 0 0;
    }
    .ant-table-title {
      padding: 10px 0 20px;
    }
    .ant-table-row {
      font-size: 14px;
      line-height: 22px;
      .medium();
      color: #484848;
    }
  }
}

// 活动设置 / 其他现货
.notice{
  font-size: 12px;
  color: #438FE7;
  font-style: normal;
  margin-top: 20px;
}

.cancelprizeBtn{
  margin-left: 10px;
}

:global{
  .addprize{
    .ant-modal-body{
      padding: 24px 60px 20 28px;
    }
    .ant-form-item{
      margin-bottom: 20px;
      .medium()
    }
  }
}

// 商品管理 / 其他现货
.commonstockheader{
  padding: 17px 20px;
  display: flex;
  align-items: center;
  .commonstocktitle{
    color: #484848;
    font-size: 18px;
    line-height: 25px;
    .semibold();
    margin-right: 20px;
  }
  :global{
    .ant-btn{
      width: 114px;
      height: 32px;
      color: #ffffff;
      font-size: 14px;
      .semibold()
    }
  }
}