.container {
  height: 100%;
}

.colorMask {
  width: 100%;
  height: 100%;
  background: linear-gradient(to top right, #4FA7FE, #81D8D1);
  position: fixed;
  top: 0;
  left: 0;
}

.mask {
  margin: 0 auto;
  border-radius: 4px;
  opacity: 0.9;
  background-color: #FFFFFF;
  width: 800px;
  max-width: 80%;
  height: 500px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.login {
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.captchaBtn {
  flex: none;
  border-radius: 4px;
  border: 1px solid #53ABF9;
  color: #53ABF9;
  text-align: center;
  margin-left: 8px;
  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
  }
}

.btnLogin {
  margin: 100px auto 0;
  border-radius: 4px;
}

.title {
  margin-bottom: 80px;
  font-size: 24px;
  color: #53ABF9;
}

.input {
  border-bottom: 1px solid #dcdcdc ;
  color: #999999;
  line-height: 34px;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
  border-radius: 0;
}

.input:focus {
  border-bottom: 1px solid #53ABF9 ;
  color: #53ABF9;
}

.input:focus::-webkit-input-placeholder {
  color: transparent;
}

.mobile, .codeLine {
  width: 280px;
}

.codeLine {
  display: flex;
  margin: 0 auto;
  text-align: left;
  margin-top: 35px;
}

.code {
  flex: 1;
}