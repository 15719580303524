@import '../../style/themes/default.less';
@import '../../style/mixins/utils.less';

.container {
  background: @menu-bg;
  flex: 0 0 @menu-width;
  max-width: @menu-width;
  min-width: @menu-width;
  width: @menu-width;
  height: 100%;
  overflow: hidden;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  .innerWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    .menuWrap {
      flex: 1 1 0%;
      overflow: hidden auto;
      padding: 14px 0;
      .hide-scrollbar();
    }
  }
}