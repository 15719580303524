@import '../../style/mixins/font-weight.less';

.font-shared {
  font-size: 14px;
  line-height: 20px;
}

.container {
  color: #fff;
  padding: 10px;
  overflow: hidden;
  .medium();
  .roleWrap {
    display: flex;
    align-items: center;
    .logout {
      cursor: pointer;
    }
    .role {
      margin-left: 10px;
      .font-shared();
    }
  }
  .avatarWrap {
    position: relative;
    width: 54px;
    height: 54px;
    margin: 16px auto 5px;
    .avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .level {
      position: absolute;
      top: -8px;
      right: -21px;
      width: 45px;
      height: 16px;
      border-radius: 12px;
    }
  }
  .name {
    text-align: center;
    margin: 5px;
    .font-shared();
  }
  .data {
    margin: 2px 0 0;
    font-size: 12px;
    line-height: 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}