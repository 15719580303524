@import '../../style/themes/default.less';

.container {
  height: 100%;
  box-sizing: border-box;
  position: relative;
  padding-top: @layout-header-height;
  padding-left: @menu-width;
  display: flex;
  .pageContainer {
    height: 100%;
    flex: 1;
    padding-top: @page-padding;
    padding-left: @page-padding;
    padding-right: @page-padding;
    box-sizing: border-box;
    overflow: hidden;
    .page {
      height: 100%;
    }
  }
  
  .helpContainer {
    background-color: @bg-color;
    box-sizing: border-box;
    border-left: 1px solid @border-color;
    height: 100%;
    flex: none;
    width: 0;
    overflow: hidden;
    transition: all @help-transition-duration;
    &.expand {
      width: @help-width;
    }
  }
}