@import '../../style/themes/default.less';
@import '../../style/mixins/utils.less';

.container {
  height: @layout-header-height;
  top: 0;
  position: fixed;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 98;
  border-bottom: 1px solid @border-color;
  background-color: @bg-color;
  padding-left: @menu-width;
  display: flex;
  .recentVisit {
    flex: 1;
    overflow: auto hidden;
    .hide-scrollbar();
  }
  .help {
    flex: none;
  }
}