@import '../../style/mixins/font-weight.less';

.item {
  padding: 0 10px;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  display: flex;
  flex: none;
  align-items: center;
  .medium();
  &.active {
    color: #484848;
  }
  .name {
    margin-right: 5px;
    cursor: pointer;
  }
  .close {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}